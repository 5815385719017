import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useAtom } from "jotai";
import { OutlinedColorPicker } from "../../../components/ColorPicker";
import { ImagePicker } from "../../../components/ImagePicker";
import { SelectFromList } from "../../../components/SelectFromList";
import { CloseButton } from "../../../components/buttons/CloseButton";
import { ProcessButton } from "../../../components/buttons/ProcessButton";
import { HtmlEditor } from "../../../components/remirror/RemirrorEditor";
import { useDialogs } from "../../../contexts/useDialogs";
import { EMPTY_GUID } from "../../../features/data/data-types";
import {
  Collection,
  createCollection,
  CollectionRef,
} from "../../../features/products/product";
import { useProducts } from "../../../features/products/product-func";
import {
  CollectionEditorOptions,
  CollectionAttrsOptions,
} from "../../../features/products/product-modal-state";
import { productDbAtom } from "../../../features/products/product.state";
import CollectionListView from "./CollectionListView";
import { useCollections } from "./collection-func";
import { IconTypes } from "../../../components/icons.types";

interface CollectionEditorModalProps extends CollectionEditorOptions {
  onClose: (opts?: CollectionEditorOptions) => void;
}

export const CollectionEditorModal = ({
  collectionId,
  onClose,
  onChange,
}: CollectionEditorModalProps) => {
  return (
    <Dialog open={!!collectionId} onClose={() => onClose()} fullScreen>
      <CollectionListView
        isModal
        collectionId={collectionId}
        onClose={(id) => {
          onChange?.(id);
          onClose();
        }}
      />
    </Dialog>
  );
};

interface CollectionAttrsModalProps extends CollectionAttrsOptions {
  onClose: (opts?: CollectionAttrsOptions) => void;
}

export const CollectionAttrsModal = ({
  collectionId,
  parentId,
  onClose,
  onChange,
  withItems,
}: CollectionAttrsModalProps) => {
  const [productDb] = useAtom(productDbAtom);

  const { processSaveCollection, processing } = useCollections();
  const { isReadOnly } = useProducts();

  const [edit, setEdit] = useState<Collection>();
  const nameRef = useRef<HTMLInputElement>();

  const { collectionTree } = useProducts();
  const { productModalApi } = useDialogs();

  const isNew = !collectionId || collectionId === EMPTY_GUID;

  useEffect(() => {
    const cr = isNew
      ? createCollection(productDb.storageUrl, { parentId })
      : productDb.collectionMap.get(collectionId);
    setEdit(cr ? { ...cr.group } : null);
    if (isNew)
      setTimeout(() => {
        nameRef.current?.focus();
      });
  }, [
    collectionId,
    isNew,
    parentId,
    productDb.collectionMap,
    productDb.storageUrl,
  ]);

  const getDependent = useCallback((c: CollectionRef): string[] => {
    return [
      c.group.id,
      ...c.children.reduce((acc, c) => [...acc, ...getDependent(c)], []),
    ];
  }, []);

  const dependentTree = useMemo(() => {
    const c = isNew ? null : productDb.collectionMap.get(collectionId);
    return c ? getDependent(c) : [];
  }, [collectionId, getDependent, isNew, productDb.collectionMap]);

  if (!edit) return null;

  return (
    <Dialog open={!!edit} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>
        Edit collection {edit?.name} <CloseButton onClose={onClose} />
      </DialogTitle>

      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            "& .MuiFormControl-root": { my: 1 },
          }}
        >
          <div>
            <FormControl fullWidth>
              <TextField
                label="Collection Name"
                value={edit.name ?? ""}
                required
                onChange={(e) => setEdit({ ...edit, name: e.target.value })}
                inputRef={nameRef}
              />
            </FormControl>
          </div>

          <div>
            <FormControl fullWidth>
              <FormLabel component="legend">Collection Description</FormLabel>
              <HtmlEditor
                value={edit.description ?? ""}
                onChange={(html) => setEdit({ ...edit, description: html })}
              />
            </FormControl>
          </div>
          <div>
            <SelectFromList
              entries={collectionTree
                .filter((c) => !dependentTree.includes(c.group.id))
                .map((c) => ({
                  value: c.group.id,
                  name: c.group.name,
                  level: c.parents.length,
                }))}
              value={edit.parentId}
              setValue={(parentId) => setEdit({ ...edit, parentId })}
              label="Parent collection"
              defaultLabel="Top collection"
            />
          </div>
          <div>
            <FormControl fullWidth>
              <TextField
                label="Rank"
                value={edit.rank ?? 0}
                type="number"
                onChange={(e) => setEdit({ ...edit, rank: +e.target.value })}
                inputProps={{ pattern: "[0-9]*" }}
              />
            </FormControl>
          </div>
          <Box my={1}>
            <OutlinedColorPicker
              color={edit.color}
              setColor={(color) => setEdit({ ...edit, color })}
            />
          </Box>
          <Box my={2}>
            <ImagePicker
              label="Collection Thumbnail"
              path={edit.thumbnail}
              setPath={(thumbnail) => setEdit({ ...edit, thumbnail })}
            />
          </Box>
        </Box>
      </DialogContent>
      {!isReadOnly && (
        <DialogActions>
          <Box sx={{ display: "flex", mx: 2, width: "100%" }}>
            {!isNew && (
              <ProcessButton
                processing={processing}
                onClick={() =>
                  productModalApi.openCollectionDelete({
                    collectionId: edit.id,
                    onDelete: () => {
                      onChange(null);
                      onClose();
                    },
                  })
                }
                label={<IconTypes.DeleteAction />}
                color="error"
                variant="outlined"
              />
            )}
            <Box flexGrow={1} />

            <Button onClick={() => onClose()}>Cancel</Button>
            <ProcessButton
              processing={processing}
              onClick={() => {
                processSaveCollection(edit, false, (id) => {
                  onClose();
                  if (withItems) {
                    productModalApi.openCollectionEditor({
                      collectionId: id,
                      onChange,
                    });
                  } else {
                    onChange(id);
                  }
                });
              }}
              label="Save"
              disabled={!edit?.name}
            />
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};
