import { useCallback, useEffect, useRef, useState } from "react";

import Moveable from "react-moveable";
import Selecto from "react-selecto";
import { OnDrag, OnResize, OnRotate } from "moveable";
import { BoundRect } from "../../features/design/design.types";
import { roundCoord } from "../../features/design/designer";
import {
  DesignerBoardApi,
  DesignerChange,
  BoardInteractionMode,
} from "./designer.board.api";
import {
  processItemImages,
  processDesignImages,
  translateElementCoordinates,
} from "./designer.func";

type DesignerMovableProps = {
  api: DesignerBoardApi;
  bounds: BoundRect;
  boardContainer: string;
  //board: DesignAreaProps;
  boardElement: HTMLDivElement;
  //dimensions: PageDimensions;
  onChange: (change: DesignerChange) => void;
};

export const useMovable = ({
  api,
  bounds,
  boardContainer,
  //board,
  boardElement,
  //dimensions,
  onChange,
}: DesignerMovableProps) => {
  const moveableRef = useRef<Moveable>(null);
  const selectoRef = useRef<Selecto>(null);

  const [isSelected, setIsSelected] = useState(false);

  const {
    builder,
    targets,
    setTargets,
    setBoardState,
    selectItem,
    editedId,
    //setBoardVersion,
  } = api;

  const dragItem = useCallback(
    (e: OnDrag, mode: BoardInteractionMode) => {
      e.target.style.left = `${roundCoord(e.left)}px`;
      e.target.style.top = `${roundCoord(e.top)}px`;
      setBoardState((s) => ({ ...s, version: s.version + 1, mode }));
    },
    [setBoardState]
  );

  const resizeItem = useCallback(
    (e: OnResize) => {
      if (e.width >= builder.board.min || e.width >= e.target.clientWidth)
        e.target.style.width = `${e.width}px`;
      if (e.height >= builder.board.min || e.height >= e.target.clientHeight)
        e.target.style.height = `${e.height}px`;
      dragItem(e.drag, "resize");
      processItemImages(e.target as HTMLElement);
    },
    [builder.board.min, dragItem]
  );

  const rotateItem = useCallback(
    (e: OnRotate) => {
      const r = e.absoluteRotation;
      e.target.style.transform = `rotate(${r}deg)`;
      e.target.dataset.rotate = `${Math.round(r)}`;
      setBoardState((s) => ({ ...s, version: s.version + 1, mode: "rotate" }));

      // const item = api.getItemById(e.target.id);
      // if (item && item.di.r !== r) {
      //   onChange({
      //     action: "rotate",
      //     type: "update",
      //     item,
      //     data: { r },
      //     soft: true,
      //   });
      //   processDesignImages();
      // }
    },
    [setBoardState]
  );

  const syncRotatedItems = useCallback(() => {
    setBoardState((s) => ({ ...s, mode: null }));
    if (targets.length) {
      targets.forEach((elem) => {
        const r = Math.round(+(elem.dataset.rotate || 0));
        const item = api.getItemById(elem.id);
        if (item && item.di.r !== r) {
          onChange({
            action: "rotate",
            type: "update",
            item,
            data: { r },
            soft: true,
          });
          processDesignImages();
        }
      });
    }
  }, [api, onChange, setBoardState, targets]);

  const syncMovedItems = useCallback(
    (action: string) => {
      setBoardState((s) => ({ ...s, mode: null }));
      if (targets.length) {
        targets.forEach((elem) => {
          const coords = translateElementCoordinates(
            elem,
            builder.getActivePage().dimensions,
            builder.board,
            1
          );
          const item = api.getItemById(elem.id);
          if (
            item &&
            (item.di.x !== coords.x ||
              item.di.y !== coords.y ||
              item.di.w !== coords.w ||
              item.di.h !== coords.h)
          ) {
            onChange({
              action,
              type: "update",
              item,
              data: { x: coords.x, y: coords.y, w: coords.w, h: coords.h },
              soft: true,
            });
            processDesignImages();
          }
        });
      }
    },
    [api, builder, onChange, setBoardState, targets]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!moveableRef.current || moveableRef.current.isDragging()) return;

      if (!editedId) {
        if (event.key === "ArrowLeft") {
          if (event.shiftKey) {
            moveableRef.current.request("resizable", {
              deltaWidth: -10,
              isInstant: true,
            });
          } else {
            moveableRef.current.request("draggable", {
              deltaX: -10,
              isInstant: true,
            });
          }
        } else if (event.key === "ArrowRight") {
          if (event.shiftKey) {
            moveableRef.current.request("resizable", {
              deltaWidth: 10,
              isInstant: true,
            });
          } else {
            moveableRef.current.request("draggable", {
              deltaX: 10,
              isInstant: true,
            });
          }
        } else if (event.key === "ArrowUp") {
          if (event.shiftKey) {
            moveableRef.current.request("resizable", {
              deltaHeight: -10,
              isInstant: true,
            });
          } else {
            moveableRef.current.request("draggable", {
              deltaY: -10,
              isInstant: true,
            });
          }
        } else if (event.key === "ArrowDown") {
          if (event.shiftKey) {
            moveableRef.current.request("resizable", {
              deltaHeight: 10,
              isInstant: true,
            });
          } else {
            moveableRef.current.request("draggable", {
              deltaY: 10,
              isInstant: true,
            });
          }
        }
      }
    },
    [editedId]
  );

  useEffect(() => {
    const updateMovable = () => {
      moveableRef.current?.updateRect();
    };

    window.addEventListener("resize", updateMovable);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("resize", updateMovable);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  //const bounds = boardElement?.getBoundingClientRect();

  useEffect(() => {
    if (!bounds) setBoardState((s) => ({ version: s.version + 1, mode: null }));
  }, [bounds, setBoardState]);

  const catalogElements = Array.from(
    document.querySelectorAll(boardContainer + " .catalog-item")
  ).filter((e) => !targets?.find((t) => t.id === e.id));

  //const root = document.querySelector(".design-board-container") as HTMLElement;

  const renderSelecto = useCallback(() => {
    if (!bounds || !builder) {
      return null;
    }
    return (
      <Selecto
        ref={selectoRef}
        //container= ".design-board-container"
        // container={boardRef.current}
        // rootContainer={boardRef.current}
        //rootContainer={root}
        boundContainer={boardElement}
        //boundContainer=".design-board-viewport"
        //dragContainer={window}
        dragContainer=".design-board-viewport"
        //dragContainer=".page-body"
        selectableTargets={[boardContainer + " .catalog-item"]}
        hitRate={0}
        selectByClick={true}
        selectFromInside={false}
        toggleContinueSelect={["shift"]}
        ratio={0}
        dragCondition={(e) => {
          const un = e.inputEvent.target.closest(".unmovable");

          if (un) return false;

          const c = e.inputEvent.target.closest(".movable-container");
          return !!c;
        }}
        onDragStart={(e) => {
          const moveable = moveableRef.current;
          const target = e.inputEvent.target;

          //const ci = e.inputEvent.target.closest(".catalog-item");

          // console.log(
          //   "onDragStart",
          //   target,
          //   ci,
          //   moveable.isMoveableElement(ci)
          // );

          if (
            moveable.isMoveableElement(target) ||
            //ci ||
            targets.some((t) => t === target || t.contains(target))
          ) {
            e.stop();
            //console.log("onDragStart-stop");
          }
          setBoardState((s) => ({
            ...s,
            version: s.version + 1,
            mode: "drag",
          }));
        }}
        onSelect={(e) => {
          setTargets(e.selected as HTMLElement[]);
          //console.log("onSelect", e.selected);
        }}
        onSelectStart={() => {
          setIsSelected(false);
          //console.log("onSelectStart", e.selected);
        }}
        onSelectEnd={(e) => {
          //console.log("onSelectEnd", e.selected);

          const moveable = moveableRef.current;

          if (e.isDragStart) {
            e.inputEvent.preventDefault();
            setTimeout(() => {
              moveable.dragStart(e.inputEvent);
            });
          }

          if (e.selected.length === 1) {
            if (!targets.includes(e.selected[0])) {
              setTargets(e.selected as HTMLElement[]);
            }
            selectItem(
              e.selected[0].id,
              e.inputEvent.type === "mousedown",
              false,
              "click"
            );
          } else {
            selectItem();
          }
          if (!e.selected.length) {
            setTargets([]);
          } else {
            setIsSelected(true);
          }
        }}
      />
    );
  }, [
    boardContainer,
    boardElement,
    bounds,
    builder,
    selectItem,
    setBoardState,
    setTargets,
    targets,
  ]);

  const renderMovable = useCallback(() => {
    if (!bounds || !builder) {
      return null;
    }
    return (
      <Moveable
        ref={moveableRef}
        target={targets}
        edge={false}
        origin={false}
        draggable={true}
        throttleDrag={0}
        snappable={true}
        bounds={bounds}
        onDrag={(ev) => {
          dragItem(ev, "drag");
        }}
        onDragEnd={() => {
          syncMovedItems("move");
        }}
        onClickGroup={(e) => {
          selectoRef.current.clickTarget(e.inputEvent, e.inputTarget);
        }}
        onDragGroup={(e) => {
          e.events.forEach((ev) => {
            dragItem(ev, "drag");
          });
        }}
        onDragGroupEnd={() => {
          syncMovedItems("move");
        }}
        keepRatio={false}
        rotatable={true}
        rotateAroundControls={true}
        throttleResize={1}
        onResize={(e) => {
          resizeItem(e);
        }}
        onResizeEnd={() => {
          syncMovedItems("resize");
        }}
        onResizeGroup={(e) => {
          e.events.forEach((ev) => {
            resizeItem(ev);
          });
        }}
        onResizeGroupEnd={() => {
          syncMovedItems("resize");
        }}
        resizable={{
          renderDirections: true,
        }}
        onRotateStart={(e) => {
          e.setFixedDirection([-0.5, -0.5]);
        }}
        onRotate={(e) => {
          rotateItem(e);
        }}
        onRotateEnd={() => {
          syncRotatedItems();
        }}
        onRotateGroup={(e) => {
          e.events.forEach((ev) => {
            rotateItem(ev);
          });
        }}
        onRotateGroupEnd={() => {
          syncRotatedItems();
        }}
        pinchable={true}
        elementGuidelines={catalogElements}
        verticalGuidelines={[bounds.left + (bounds.right - bounds.left) / 2]}
        horizontalGuidelines={[bounds.top + (bounds.bottom - bounds.top) / 2]}
        snapThreshold={5}
        isDisplaySnapDigit={true}
        snapGap={true}
        snapDirections={{
          top: true,
          right: true,
          bottom: true,
          left: true,
        }}
        elementSnapDirections={{
          top: true,
          right: true,
          bottom: true,
          left: true,
          center: true,
          middle: true,
        }}
        snapDigit={0}
        // scrollable={true}
        // scrollOptions={{
        //   container: ".design-board-container",
        //   threshold: 30,
        //   checkScrollEvent: false,
        //   throttleTime: 0,
        // }}
        // onScroll={({ scrollContainer, direction }) => {
        //   scrollContainer.scrollBy(direction[0] * 10, direction[1] * 10);
        // }}
      />
    );
  }, [
    bounds,
    builder,
    catalogElements,
    dragItem,
    resizeItem,
    rotateItem,
    syncMovedItems,
    syncRotatedItems,
    targets,
  ]);

  return { renderMovable, renderSelecto, isSelected, setIsSelected };
};
