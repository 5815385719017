import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import { Link as RouterLink } from "react-router-dom";
import { sessionAtom } from "../../features/accounts/account.state";
import { EntityType } from "../../features/data/data-types";

export interface UpgradeDialogProps {
  type: EntityType;
  isOpen?: boolean;
  onClose?: () => void;
}

export type OpenUpgradeModalFunc = (type: EntityType) => void;

export const UpgradeDialog = ({
  type,
  isOpen,
  onClose,
}: UpgradeDialogProps) => {
  const [session] = useAtom(sessionAtom);

  let limits = "";

  if (type === "products") {
    limits = session.account?.limits.items + " products";
  }

  if (type === "images") {
    limits = session.account?.limits.space + " MB storage";
  }

  if (type === "catalogs") {
    limits = session.account?.limits.catalogs + " catalogs";
  }
  if (type === "pages") {
    limits = session.account?.limits.pageLimit + " pages per catalog";
  }
  if (type === "users") {
    limits = session.account?.limits.users + 1 + " users";
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle variant="h5">
        You have reached limit of {limits}.
      </DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: "center", my: 3 }}>
          <img
            src="/images/upgrade-gear.png"
            alt="upgrade plan"
            style={{ maxWidth: 128, opacity: 0.7 }}
          />
          <Typography variant="h6" sx={{ m: 2 }}>
            Upgrade your plan to add more {type}.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={"/account/billing/plan"}
          onClick={onClose}
        >
          Upgrade now
        </Button>
      </DialogActions>
    </Dialog>
  );
};
