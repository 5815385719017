import { EMPTY_GUID } from "../data/data-types";
import {
  DesignItem,
  DesignItemType,
  ImagePosition,
  DesignItemInfo,
  DesignItemRef,
} from "../design/design.types";
import { parseImagePath } from "../images/image";
import { Layout, LayoutItem } from "../layouts/layouts";
import { PdfJobStatus } from "../pdf/pdf.builder";
import { TemplateRef } from "../templates/templates";
import { PageSizeName, CATALOG_PLACEHOLDER_THUMB } from "./catalog.defs";
import { PageDimensions } from "./catalog.design";
import { CatalogProductSource, ProductContainer } from "./catalog.products";

export const orderByToc = ["Name", "Code", "Page Number"] as const;
export type OrderByTocType = (typeof orderByToc)[number];

export const tocFontSize = ["10", "11", "12", "14", "16"];
export const sectionLevel = ["", "1", "2", "3", "4"];
export const sectionStyle = [
  { name: "with spaces", value: "" },
  { name: "with numbers", value: "num" },
];

export type PageItemConfig = {
  img?: string;
  fontColor?: string;
  globalToc?: boolean;
  ignoreProducts?: boolean;
  ignoreSections?: boolean;
  hideCode?: boolean;
  orderByToc?: OrderByTocType;
  hideHeader?: boolean;
  hideToc?: boolean;
  tocFontSize?: number;
  tocSectionFontColor?: string;
  autoToc?: boolean;
  collectionId?: string;
  templateId?: string;
  inStock?: boolean;
  catalogId?: string;
  sectionLevel?: string;
  sectionStyle?: string;
  headerBkgColor?: string;
  footerBkgColor?: string;
  w?: number;
  h?: number;
  gridId?: number;
  gridName?: string;
  useLayout?: boolean;
  layoutSet?: string;
  title?: string;
  productSource?: CatalogProductSource;
  imgPosition?: ImagePosition;
};

export type CatalogPageType =
  | "page"
  | "toc"
  | "collection"
  | "subcatalog"
  | "products";

export type ItemSinkType = "image" | "text" | "template";

export type CatalogItem = DesignItem & {
  Type: DesignItemType;
  ItemId?: string;
  TemplateId?: string;
  Body?: string;
  Position?: number;
  PageId?: number;

  Height?: number;
  Width?: number;
  ImgPosition?: ImagePosition;
  OverlayTemplateId?: string;
  OverrideOverlay?: boolean;

  PageType?: CatalogPageType;
  Title?: string;
  Sink?: ItemSinkType;

  Extension?: string;
};

export type CatalogItemRef = {
  di: CatalogItem;
  meta: "catalog";
  info?: {
    config?: PageItemConfig;
    //text?: string;
    //caption?: string;
    isEmpty?: boolean;
    //oldValue?: string;
    timestamp?: number;
    url?: string;
    //thumbSrc?: string;
    bgStyle?: BgStyle;
    //fixed?: boolean;
    //generated?: boolean;
    nextToc?: boolean;
    //thumbTitle?: string;
    sectionTitle?: string;
    subcatalog?: CatalogRef;
  } & DesignItemInfo;
} & DesignItemRef;

export type CatalogPage = {
  pageSize: PageSizeName;
  pageWidthInches?: string;
  pageHeightInches?: string;
  pdfSize?: string;
  orientation?: string;
  pageWidth?: number;
  pageHeight?: number;
};

export type CatalogTheme = {
  primeColor?: string;
  bgColor?: string;
  fontColor?: string;
  headerColor?: string;
  baseFont?: string;
  headerFont?: string;
};

export type CatalogStatus = "Draft" | "Published";

export const PRIVACY_PROTECTED = 3;

export type CatalogPdfSettings = {
  pdfWhiteBg?: boolean;
  pdfCompression?: number;
  pdfMargin?: number;
  catalogPage?: CatalogPage;
};

export type Catalog = {
  id: string;
  name: string;
  description?: string;
  created?: string;
  modified?: string;
  thumbnail?: string;
  status?: CatalogStatus;
  catalogItems?: string;

  permalink?: string;
  catalogPath?: string;

  cover?: string;
  notes?: string;
  header?: string;
  footer?: string;
  pages?: number;
  altHeader?: string;
  altFooter?: string;
  html?: boolean;
  pdf?: boolean;

  //Privacy: 0 - public; 1- direct; 2 - hidden; 3 - protected; 4 - email
  privacy?: number;

  feature?: number;
  headerOnFirst?: boolean;
  headerOnLast?: boolean;

  headerHeight?: number;
  footerHeight?: number;
  headerBkgColor?: string;
  footerBkgColor?: string;
  headerBkgColorSrc?: string;
  footerBkgColorSrc?: string;
  headerMargin?: string;
  footerMargin?: string;
  navigation?: string;
  version?: number;
  rank?: number;
  overlayTemplateId?: string;
  compression?: number;
  social?: boolean;

  noImageOptimization?: boolean;
  fastImageUrl?: boolean;
  accessCode?: string;
  basePrice?: string;
  lastMode?: string;
  enableOrders?: boolean;
  viewBkgColor?: string;
  viewBkgImage?: string;
  startMode?: string;
  hideOther?: boolean;
  storeLinked?: boolean;
  storeId?: number;
  storeButton?: string;
} & CatalogTheme &
  CatalogPdfSettings;

export type PageEntry = {
  id: number;
  range: {
    start: number;
    end: number;
    viewStart: number;
    viewEnd: number;
  };
  grid?: CatalogGrid;
  pages: CatalogPageRef[];
  originalPage?: CatalogPageRef;
  multiple?: boolean;
  readOnly?: boolean;
  section?: CatalogSection;
  subcatalog?: CatalogRef;
};

export type CatalogRef = {
  catalog: Catalog;
  thumb: string;
  pages?: CatalogPageRef[];
};

export type CatalogPageRef = {
  id: string;
  name?: string;
  pageId: number;
  items: CatalogItemRef[];
  pageItem?: CatalogItemRef;
  headerHeight?: number;
  footerHeight?: number;
  pageHeight?: number;
  dimensions?: PageDimensions;
  headerContent?: string;
  footerContent?: string;
  headerBkgColor?: string;
  footerBkgColor?: string;
  readonly?: boolean;
  entry?: PageEntry;
  viewPageId?: number;
  addedElements?: number;
};

export type BgStyle = {
  backgroundColor: string;
  borderStyle: string;
  borderColor: string;
  borderRadius: number;
  borderWidth: number;
};

export type CatalogGrid = {
  id: number;
  type?: CatalogPageType;
  index?: number;
  name?: string;
  title?: string;
  isNew?: boolean;
  sectionTitle?: string;
  templateId?: string;
  template?: TemplateRef;

  items: CatalogItemRef[];
  w: number;
  h: number;
  begin?: number;
  end?: number;
  useLayout?: boolean;
  layoutSet?: string;
  layouts?: { role?: string; layout?: Layout; items?: LayoutItem[] }[];

  preview?: {
    pages: CatalogPageRef[];
    pageId: number;
  };

  expanded?: boolean;
  handle?: string;
  pageItem?: CatalogItemRef;
} & ProductContainer;

export type LayoutSetItem = {
  layoutId: string;
  role?: string;
};

export type CatalogSection = {
  id: string;
  title: string;
  expanded: boolean;
  start: number;
  end: number;
};

export type CatalogBackupType = "auto" | "manual";

export const mapCatalog = (
  catalog: Catalog,
  storageUrl: string
): CatalogRef => {
  const thumb = catalog.thumbnail
    ? parseImagePath(storageUrl, catalog.thumbnail)?.thumb
    : CATALOG_PLACEHOLDER_THUMB;
  return {
    catalog,
    thumb,
  };
};

export const createNewCatalog = (name: string) => {
  const catalog: Catalog = {
    id: EMPTY_GUID,
    name,
    catalogPage: { pageSize: "Portrait" },
    catalogItems: "[]",
  };
  return catalog;
};

export const createTempCatalog = (page?: CatalogPage) => {
  const catalog: Catalog = {
    id: "",
    name: "",
    catalogPage: page ?? { pageSize: "Portrait" },
  };
  return catalog;
};

export const isNewCatalog = (catalogId: string) => {
  return catalogId === EMPTY_GUID || catalogId === "new";
};

export type OnPdfFunc = (status: PdfJobStatus) => void;

export type CatalogSaveArgs = {
  refreshPdf?: boolean;
  backupType?: CatalogBackupType;
  onFinish?: () => void;
  silent?: boolean;
  force?: boolean;
  onPdf?: OnPdfFunc;
};
