import { v4 as uuidv4 } from "uuid";
import { CatalogBuilder } from "../catalogs/catalog.builder";
import {
  InitItemArgs,
  syncItemAttrs,
  UpdateItemAtrs,
  CreateItemAttrs,
} from "../catalogs/catalog.design";
import { getImageSrc } from "../catalogs/catalog.func";
import { newItem } from "../catalogs/catalog.item";
import { getBgStyle, initBg } from "../catalogs/catalog.style";
import {
  initTemplateItems,
  normalizeTemplate,
} from "../catalogs/catalog.template";
import { CatalogPageRef } from "../catalogs/catalogs";
import { initProductListExt } from "../catalogs/product-list.ext";
import { EMPTY_GUID, DefaultImageField } from "../data/data-types";
import { generateNewEntityName } from "../data/entity";
import {
  DesignItem,
  DesignItemType,
  ImagePosition,
  TemplateItemRef,
  DesignItemInfo,
  DesignItemRef,
  setRotationStyle,
  imagePositions,
  orderDesignItem,
  removeDesignItem,
} from "../design/design.types";
import { templateGridSize } from "../design/designer";
import { ProductRef } from "../products/product";
import { LayoutConvertors } from "./layout.convertors";
import { positionElement } from "../../containers/designer/designer.func";

export type LayoutDbRaw = {
  standard: Layout[];
  account: Layout[];
};

export type LayoutDb = {
  standard: LayoutRef[];
  account: LayoutRef[];
};

export const PriceListLayoutCategory = "Price List";
export const MyLayoutsCategory = "My Layouts";

export type Layout = {
  id: string;
  name: string;
  modified?: string;
  description?: string;
  body?: string;
  standard?: boolean;
  shared?: boolean;
  likes?: number;
  rank?: number;
  copied?: number;
  sourceId?: string;
  copiedOn?: string;
  used?: number;
  copyUsed?: number;
  category?: string;
  rated?: string;
};

export type LayoutRef = {
  layout: Layout;
  items?: LayoutItemRef[];
  readonly?: boolean;
  special?: boolean;
  content?;
};

export type LayoutItem = DesignItem & {
  type?: DesignItemType;
  title?: string;
  text?: string;
  Extension?: string;
  src?: string;
  position?: ImagePosition;
  field?: string;
  templateId?: string;
  th?: number;
  tw?: number;
};

export type LayoutItemRef = {
  di: LayoutItem;
  meta: "layout";
  info?: {
    templateItems?: TemplateItemRef[];
  } & DesignItemInfo;
} & DesignItemRef;

export type LayoutAction = "like" | "copy" | "use";

export const createNewLayout = (
  builder: CatalogBuilder,
  copy?: Layout
): Layout => {
  return {
    body: "[]",
    ...copy,
    id: EMPTY_GUID,
    name: generateNewEntityName(
      builder.data.layoutDb.account,
      copy?.name ?? "Layout",
      (t) => t.layout.name
    ),
    standard: false,
    sourceId: copy?.id,
    shared: false,
  };
};

export const initLayoutItem = (
  builder: CatalogBuilder,
  item: LayoutItemRef,
  args?: InitItemArgs
) => {
  item.info = item.info || {};
  item.info.title = item.di.title || "";
  item.info.content = item.di.text || "";

  item.info.extension = initProductListExt(item.di.Extension);

  if (item.type === "text") {
    item.info.content = item.di.text;
    if (item.di.subtype === "product" && !item.di.title)
      item.info.title = "Product";
  }
  if (item.type === "image") {
    if (item.di.src) {
      item.info.src = getImageSrc(
        builder.data.config.imageStorageUrl,
        item.di.src,
        false,
        item.version
      );
    } else {
      item.info.imageField = item.di.field || DefaultImageField;
      if (item.di.field && item.di.field !== DefaultImageField)
        item.info.title += " [" + item.di.field + "]";
      if (!item.info.title) item.info.title = item.di.text || "Image";
    }
  }

  if (item.type === "template") {
    item.info.template = builder.data.templates.find((i) => {
      return i.template.id === item.di.templateId;
    });

    const dimensions = args?.dimensions ?? builder.getActivePage()?.dimensions;
    initTemplateItems(builder, {
      ...args,
      parent: item,
      dimensions,
    });

    const tname = item.info.template?.template.name;
    item.info.title =
      (item.info.title || "Card ") + (tname ? " [" + tname + "]" : "");
  }

  updateLayoutItemStyle(builder, item, args);

  return syncItemAttrs(item);
};

export const updateLayoutItemStyle = (
  builder: CatalogBuilder,
  item: LayoutItemRef,
  args?: InitItemArgs
) => {
  const dimensions = args?.dimensions ?? builder.getActivePage()?.dimensions;
  if (!dimensions) return;
  item.style = {
    left: Math.round(item.di.x * dimensions.scaleX),
    top: Math.round(item.di.y * dimensions.scaleY),
    width: Math.round(item.di.w * dimensions.scaleX),
    height: Math.round(item.di.h * dimensions.scaleY),
    zIndex: item.di.z || 1,
  };
  setRotationStyle(item);
  if (item.type === "icon") {
    item.style.color = item.di.Color;
    item.style.fontSize = Math.min(item.style.width, item.style.height) + "px";
  }
  if (item.type === "bg") {
    item.style = {
      ...item.style,
      ...getBgStyle(item, 0, builder),
    };
  }
};

export const updateLayoutItemsStyle = (
  builder: CatalogBuilder,
  layout: LayoutRef,
  args?: InitItemArgs
) => {
  layout.items?.forEach((i) => {
    updateLayoutItemStyle(builder, i, args);
  });
};

export const getLayoutItems = (layout: Layout): LayoutItem[] => {
  try {
    const items = JSON.parse(layout.body || "[]");
    return items;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const newLayoutItem = (ti: LayoutItem) => {
  return syncItemAttrs<LayoutItemRef>({
    id: uuidv4(),
    version: 0,
    di: ti,
    info: {},
    meta: "layout",
  });
};

export const updateLayoutItemContent = (
  li: LayoutItem,
  attrs: UpdateItemAtrs | CreateItemAttrs
) => {
  const type = attrs.type ?? li.type;
  if (type === "product-image" || type === "image") {
    li.src = type === "product-image" ? null : attrs.content;
    li.field = type === "product-image" ? attrs.content : null;
    li.position = li.position ?? imagePositions[0];
    li.type = "image";
  } else {
    li.text = attrs.content;
  }
};

export const updateLayoutItemTemplate = (
  builder: CatalogBuilder,
  li: LayoutItem,
  withNormalize: boolean
) => {
  if (li.templateId) {
    const template = builder.data.templates.find(
      (t) => t.template.id === li.templateId
    );
    if (template) {
      li.tw = template.template.width;
      li.th = template.template.height;
      li.text = template.template.name;
      if (withNormalize) normalizeTemplate(li, template);
    }
  }
};

export const createLayoutItem = (
  builder: CatalogBuilder,
  layout: LayoutRef,
  attrs: CreateItemAttrs & InitItemArgs
) => {
  const { type, subtype, x, y, pos } = attrs;

  const w = attrs.w || builder.board.newSize;
  const h = attrs.h || builder.board.newSize;

  const d = attrs.dimensions ?? builder.getActivePage().dimensions;

  const li: LayoutItem = {
    type,
    subtype,
    w: Math.floor(
      (w > builder.board.min ? w : builder.board.min) / (d.scaleX ?? 1)
    ),
    h: Math.floor(
      (h > builder.board.min ? h : builder.board.min) / (d.scaleY ?? 1)
    ),
    z: 1,

    Color: attrs.color,
    Extension: attrs.ext,
    templateId: attrs.templateId,
    ...attrs.clone,
  };

  if (attrs.type === "bg" && !attrs.clone) {
    initBg(li);
  }

  updateLayoutItemTemplate(builder, li, false);

  if (pos === "none") {
    positionElement(li, builder.getActivePage());
  } else {
    li.x = Math.floor((x >= 0 ? x : 0) / (d.scaleX ?? 1));
    li.y = Math.floor((y >= 0 ? y : 0) / (d.scaleY ?? 1));
  }

  if (li.w + li.x > templateGridSize) li.x = templateGridSize - li.w;
  if (li.h + li.y > templateGridSize) li.y = templateGridSize - li.h;

  updateLayoutItemContent(li, attrs);

  const item = newLayoutItem(li);

  layout.items.push(item);
  orderLayoutItem(layout, item, type !== "bg");
  initLayoutItem(builder, item, attrs);

  return item;
};

export const initLayout = (
  builder: CatalogBuilder,
  l: LayoutRef,
  withInit: boolean,
  force = false
) => {
  if (l.items && !force) return;
  l.items = getLayoutItems(l.layout).map((i) => {
    const item = newLayoutItem(i);
    return withInit ? initLayoutItem(builder, item, {}) : item;
  });
};

export const getAllLayouts = (layoutDb: LayoutDb) => {
  return layoutDb.account
    .concat(layoutDb.standard)
    .sortList((l) => l.layout.name);
};

export const injectLayoutItems = (
  builder: CatalogBuilder,
  page: CatalogPageRef,
  opts: {
    x: number;
    y: number;
    kw: number;
    kh: number;
    isPreview?: boolean;
    items?: LayoutItem[];
    products?: ProductRef[];
    productId?: string;
  }
) => {
  const map: { [key: string]: ProductRef } = {};
  const items = opts.items ? [...opts.items] : [];
  items.sort(function (a, b) {
    return Math.round((a.y - b.y) / 5) * 5 * templateGridSize * 10 + a.x - b.x;
  });
  items.forEach((li) => {
    const c = LayoutConvertors[li.type];
    if (c) {
      let itemId =
        li.type === "text" || li.type === "image" ? opts.productId : undefined;
      if (
        !itemId &&
        opts.products &&
        (((li.title || li.subtype === "product") && li.type === "text") ||
          ((li.field || li.text) && li.type === "image") ||
          li.type === "template")
      ) {
        const p =
          li.title && map[li.title] ? map[li.title] : opts.products.shift();
        if (p) {
          if (li.title) map[li.title] = p;
          itemId = p.product.id;
        }
      }
      const ci = c.convert(
        li,
        {
          x: Math.floor(li.x * opts.kw + opts.x),
          y: Math.floor(li.y * opts.kh + opts.y),
          w: Math.floor(li.w * opts.kw),
          h: Math.floor(li.h * opts.kh),
          r: li.r,
          exp: li.exp,
          fix: li.fix,
          scale: li.scale,
          Type: li.type,
          Color: li.Color,
          Position: li.z,
          Title: li.title,
          PageId: page.pageId,
          ItemId: itemId,
        },
        builder.data
      );
      const item = newItem(ci);
      builder.initItem(item);
      page.items.push(item);
      builder.items.push(item);
    }
  });
};

export const convertPageToLayout = (
  builder: CatalogBuilder,
  page: CatalogPageRef
): Layout => {
  const l = createNewLayout(builder);
  const items = [];
  page.items.forEach((item) => {
    const c = LayoutConvertors[item.type];
    if (c) {
      const li = c.toLayout(item, {
        x: Math.floor(item.di.x),
        y: Math.floor(item.di.y),
        w: Math.floor(item.di.w),
        h: Math.floor(item.di.h),
        r: item.di.r,
        exp: item.di.exp,
        fix: item.di.fix,
        scale: item.di.scale,
        type: item.type,
        Color: item.di.Color,
        z: item.di.z,
        title: item.di.Title,
      });
      items.push(li);
    }
  });

  l.body = JSON.stringify(items);
  return l;
};

export const orderLayoutItem = (
  layout: LayoutRef,
  item: LayoutItemRef,
  isFront?: boolean,
  withStyle?: boolean
) => {
  if (!item) return;
  layout.items = orderDesignItem(layout.items, item, isFront, withStyle);
};

export const removeLayoutItem = (layout: LayoutRef, item: LayoutItemRef) => {
  if (!item) return;
  layout.items = removeDesignItem(layout.items, item);
};
