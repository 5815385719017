import { useRemirrorContext } from "@remirror/react";
import { createContext, useCallback, useEffect, useState } from "react";
import { prosemirrorCmCodeToHtml } from "./extensions/cm-code-extension";
import { html as beautify_html } from "js-beautify";
import { EditorField } from "../../features/data/editor";

type HtmlEditorCommonProps = {
  fields?: EditorField[];
  fieldArgs?;
  fullScreen?: boolean;
  setFullScreen?: (on: boolean) => void;
};

export type HtmlEditorContentProps = {
  value: string;
  onChange: (value: string) => void;
} & HtmlEditorCommonProps;

export type HtmlEditorProps = {
  children?: React.ReactElement | React.ReactElement[];
} & HtmlEditorContentProps;

export type HtmlEditorContextProps = {
  isSourceEdit?: boolean;
  setSourceEdit?: (on: boolean) => void;
  onChange?: () => void;
  editedHtml: string;
  setEditedHtml: (html: string) => void;
  toHtml: () => string;
  darkBkg?: boolean;
  setDarkBkg?: (on: boolean) => void;
} & HtmlEditorCommonProps;

// export type HtmlEditorProviderProps =
//   { setContent: (value: string) => void } & HtmlEditorProps

export const HtmlEditorContext = createContext<HtmlEditorContextProps>(null);

export const HtmlEditorProvider = (props: HtmlEditorProps) => {
  const [isSourceEdit, setSourceEdit] = useState(false);
  const [darkBkg, setDarkBkg] = useState(false);
  const [lastContent, setLastContent] = useState(props.value);
  const [editedHtml, setEditedHtml] = useState(props.value);

  const { setContent, view } = useRemirrorContext();

  useEffect(() => {
    if (lastContent !== props.value && !isSourceEdit) {
      setContent(props.value);
      setLastContent(props.value);
    }
  }, [isSourceEdit, lastContent, props, props.value, setContent]);

  const toHtml = useCallback(() => {
    const htmlString = prosemirrorCmCodeToHtml(view.state.doc);
    const h = beautify_html(htmlString);
    return h;
  }, [view.state.doc]);

  const onChange = useCallback(() => {
    const value = toHtml();
    if (value !== props.value) {
      props.onChange?.(value);
      setLastContent(value);
    }
    setEditedHtml(value);
  }, [props, toHtml]);

  return (
    <HtmlEditorContext.Provider
      value={{
        onChange,
        isSourceEdit,
        setSourceEdit,
        editedHtml,
        setEditedHtml,
        toHtml,
        darkBkg,
        setDarkBkg,
        fields: props.fields,
        fieldArgs: props.fieldArgs,
        fullScreen: props.fullScreen,
        setFullScreen: props.setFullScreen,
      }}
    >
      {props.children}
    </HtmlEditorContext.Provider>
  );
};
