import { v4 as uuidv4 } from "uuid";
import { onlyUnique } from "../../services/util";
import {
  DataType,
  DefaultPriceField,
  DataTypeName,
  DefaultImageField,
  createPermalink,
  EMPTY_GUID,
  parseMoney,
  ExtendedDataType,
} from "../data/data-types";
import { toUtcFormat } from "../data/time";
import { parseImagePath } from "../images/image";

export type ProductField = {
  name: string;
  fieldId?: number;
  value?: string;
  dataType?: DataType;
  thumb?: string;
  reserved?: boolean;
  readonly?: boolean;
};

export type ProductOption = {
  optionId: number;
  name: string;
  value?: string;
  count?: number;
};

export type Category = {
  id: string;
  parentId?: string;
  name: string;
  description?: string;
  fields: ProductField[];
  options: number[];
  detailsTemplateId?: string;
  listTemplateId?: string;
  thumbnail?: string;
  color?: string;
  rank: number;
};

export type Collection = {
  id: string;
  parentId?: string;
  name: string;
  description?: string;
  thumbnail?: string;
  color?: string;
  rank: number;
  items: string[];
};

export type Product = {
  id: string;
  categoryId: string;
  code: string;
  name: string;
  created: string;
  modified: string;
  rank: number;
  inStock?: boolean;
  itemListTemplateId?: string;
  itemDetailsTemplateId?: string;
  syncAt?: string;
  syncWith?: string;
  status?: string;
  fields: ProductField[];
  variants?: ProductVariant[];
};

export type ProductVariant = {
  id: number;
  productId: string;
  description?: string;
  image?: string;
  thumb?: string;
  price?: string;
  sku?: string;
  externalId?: string;
  position?: number;
  options: ProductOption[];
  barcode?: string;
  quantity?: number;
};

export type ProductRef = {
  id: string;
  category: CategoryRef;
  product: Product;
  fieldMap: Map<string, ProductField>;
  isNew?: boolean;
  thumb?: string;
  collections?: CollectionRef[];
};

export type GroupType = "category" | "collection";

export interface GroupRef<T> {
  type: GroupType;
  totalProductCount: number;
  productCount: number;
  fullName: string;
  handle: string;
  thumb?: string;
  parent?: T;
  children: T[];
  parents: T[];
  extProductCount?: number;
  extInStock?: boolean;
}

export interface CategoryRef extends GroupRef<CategoryRef> {
  type: "category";
  group: Category;
  fieldMap: Map<number, ProductField>;
  options: ProductOption[];
}

export interface CollectionRef extends GroupRef<CollectionRef> {
  type: "collection";
  group: Collection;
}

export type ProductGroupRef = CategoryRef | CollectionRef;

export type DataChange = {
  timestamp: string;
  userId: number;
  userName: string;
};

export type ProductDb = {
  storageUrl: string;
  categoryMap: Map<string, CategoryRef>;
  productMap: Map<string, ProductRef>;
  optionMap: Map<number, ProductOption>;
  collectionMap: Map<string, CollectionRef>;
  version: number;
};

export type ProductDbRaw = {
  options: ProductOption[];
  products: Product[];
  categories: Category[];
  collections: Collection[];
  dataChange?: DataChange;
};

export type ProductUpdate = {
  id: string;
  categoryId: string;
  fields: { field: string; value?: string }[];
};

export type ProductVariantUpdate = {
  field: string;
  value?: string;
  id: string;
  optionId?: number;
  productId?: string;
};

export type ProductFieldUpdate = {
  id: string;
  categoryId: string;
  isNew: string;
  fields: {
    field: string;
    value;
  }[];
};

export type VariantFieldUpdate = {
  field: string;
  value;
  id: string;
  productId: string;
  optionId?: number;
};

export const productsSortBy = [
  { n: "Last Updated", v: "modified", o: -1 },
  { n: "Name", v: "name", o: 1 },
];

export const reservedFieldNames = [
  "Name",
  "Code",
  "Modified",
  "Category",
  "Price",
  "Description",
  "Rank",
];

export const reservedFields: ProductField[] = [
  { name: DefaultPriceField, dataType: DataTypeName.Money, fieldId: -1 },
  { name: DefaultImageField, dataType: DataTypeName.Image, fieldId: -2 },
  { name: "Description", dataType: DataTypeName.RichText, fieldId: -3 },
];

export const markProductMoidified = (p: ProductRef) => {
  p.fieldMap.get("Modified").value = new Date().toISOString();
};

export const refreshProductFields = (
  p: ProductRef,
  u: ProductUpdate,
  storageUrl: string
) => {
  u.fields.forEach((uf) => {
    const f = p.fieldMap.get(uf.field);
    if (f && !f.readonly) {
      f.value = uf.value;
      if (f.reserved) {
        p.product = { ...p.product, [f.name.toLowerCase()]: uf.value };
      } else {
        if (f.dataType === DataTypeName.Image) {
          f.thumb = f?.value
            ? parseImagePath(storageUrl, f.value)?.thumb
            : null;
        }
        const pf = p.product.fields.find((f2) => f2.fieldId === f.fieldId);
        if (pf) {
          pf.value = uf.value;
          pf.thumb = f.thumb;
        } else {
          p.product.fields.push(f);
        }
      }
    }
  });
  markProductMoidified(p);
};

export const refreshVariantField = (
  p: Product,
  id: number,
  field: string,
  optionId: number,
  value: string,
  storageUrl: string,
  newId?: number,
  ref?: ProductRef
) => {
  const variants = [...(p.variants ?? [])];
  const i = variants?.findIndex((v) => v.id === id);
  if (i < 0) return;

  const variant = (variants[i] = { ...variants[i] });

  if (newId) variant.id = newId;

  if (optionId > 0) {
    const opt = variant.options.find((o) => o.optionId === optionId);
    if (opt) {
      opt.value = value;
    } else {
      variant.options.push({ name: field, optionId, value });
    }
  } else {
    variant[field] = value;
    if (field === "image") {
      variant.thumb = value ? parseImagePath(storageUrl, value)?.thumb : null;
    }
  }
  if (ref) {
    ref.product = { ...ref.product, variants };
    ref.fieldMap.get("Modified").value = new Date().toISOString();
  } else {
    p.variants = variants;
  }
};

export const getProductSpecialFields = (p: Product, c: CategoryRef) => {
  const specialFields: [string, ProductField][] = [
    [
      "Name",
      {
        name: "Name",
        value: p.name,
        reserved: true,
      },
    ],
    [
      "Code",
      {
        name: "Code",
        value: p.code,
        reserved: true,
      },
    ],
    [
      "Modified",
      {
        name: "Modified",
        value: p.modified,
        reserved: true,
        readonly: true,
        dataType: DataTypeName.Date,
      },
    ],
    [
      "Category",
      {
        name: "Category",
        value: c.group.id,
        reserved: true,
        readonly: true,
      },
    ],
    [
      "Rank",
      {
        name: "Rank",
        value: "" + p.rank,
        reserved: true,
        dataType: DataTypeName.Number,
      },
    ],
  ];
  return specialFields;
};

export const createProduct = (
  category: CategoryRef,
  productId: string = null
): ProductRef => {
  const id = productId ?? uuidv4();
  const ts = new Date().toISOString();
  const product: Product = {
    id,
    categoryId: category.group.id,
    name: "",
    code: "",
    fields: [],
    modified: ts,
    created: ts,
    rank: 1000,
  };
  return {
    id,
    product,
    category,
    fieldMap: new Map([...getProductSpecialFields(product, category)]),
  };
};

export const mapProduct = (
  p: Product,
  categoryMap: Map<string, CategoryRef>,
  optionMap: Map<number, ProductOption>,
  storageUrl: string,
  ref?: ProductRef
) => {
  const c = categoryMap.get(p.categoryId);
  c.totalProductCount++;
  c.productCount++;

  p.created = toUtcFormat(p.created);
  p.modified = toUtcFormat(p.modified);
  p.syncAt = toUtcFormat(p.syncAt);

  p.variants = p.variants?.sortList((v) => v.position);

  p.variants?.forEach((v) => {
    v.thumb = parseImagePath(storageUrl, v.image)?.thumb;
    v.productId = p.id;
  });

  mapProductOptions(p, optionMap);

  const fieldMap = new Map(getProductFields(storageUrl, p, c));

  const img = fieldMap.get(DefaultImageField);
  const thumb = img?.value
    ? parseImagePath(storageUrl, img.value)?.thumb
    : null;

  if (ref) {
    ref.product = p;
    ref.fieldMap = fieldMap;
    ref.category = c;
    ref.thumb = thumb;
    return ref;
  }

  return {
    id: p.id,
    product: p,
    category: c,
    thumb,
    fieldMap,
  } as ProductRef;
};

export const addProductVariant = (product: Product) => {
  const variants = [...(product.variants ?? [])];
  const id = -1 - variants.length;
  variants.push({ id, productId: product.id, options: [] });
  product.variants = variants;
};

export const getProductFields = (
  storageUrl: string,
  p: Product,
  c: CategoryRef
) => {
  const fields = c.group.fields.map((f): [string, ProductField] => {
    const pf = p.fields.find((pf) => pf.fieldId === f.fieldId);
    if (f.dataType === DataTypeName.Image && pf?.value) {
      pf.thumb = parseImagePath(storageUrl, pf.value)?.thumb;
    }
    if (pf) {
      pf.dataType = f.dataType;
    }
    return [f.name, { ...f, ...pf }];
  });

  const specialFields = getProductSpecialFields(p, c);

  return [...specialFields, ...fields];
};

export const mapCategory = (
  c: Category,
  optionMap: Map<number, ProductOption>,
  storageUrl: string
) => {
  return {
    //type: "category",
    group: c,
    fieldMap: new Map(c.fields.map((f) => [f.fieldId, f])),
    options: c.options.map((optid) => optionMap.get(optid)),
    totalProductCount: 0,
    productCount: 0,
    thumb: c.thumbnail ? parseImagePath(storageUrl, c.thumbnail)?.thumb : null,
    //rank: c.rank,
    //color: c.color,
  } as CategoryRef;
};

const getParents = <T extends GroupRef<T>>(c: T) => {
  return c.parent ? [...getParents(c.parent), c.parent] : [];
};

export const refreshCategoryMap = (categoryMap: Map<string, CategoryRef>) => {
  const categories = Array.from(categoryMap.values());
  categories.forEach((c) => {
    c.children = [];
  });
  categories.forEach((c) => {
    c.parent = c.group.parentId ? categoryMap.get(c.group.parentId) : null;
    if (c.parent) {
      c.parent.children = [...c.parent.children, c];
    }
  });
  categories.forEach((c) => {
    c.parents = getParents(c);
    c.handle = createPermalink(
      c.parents.reduce((acc, p) => `${acc}${p.group.name}-`, "") + c.group.name
    );
    //c.name = c.group.name;
    //c.description = c.group.description;
    c.fullName =
      c.parents.reduce((acc, p) => `${acc}${p.group.name} | `, "") +
      c.group.name;
  });
};

export const mapCollection = (c: Collection, storageUrl: string) => {
  return {
    //type: "collection",
    group: c,
    totalProductCount: 0,
    productCount: 0,
    thumb: c.thumbnail ? parseImagePath(storageUrl, c.thumbnail)?.thumb : null,
    //rank: c.rank,
    //color: c.color,
  } as CollectionRef;
};

export const getAllUniqueCollectionItems = (col: CollectionRef) => {
  const items = col.children.reduce(
    (acc, c) => [...acc, ...getAllUniqueCollectionItems(c)],
    col.group.items
  );
  return items.filter(onlyUnique);
};

const getProductCount = (col: CollectionRef) => {
  return getAllUniqueCollectionItems(col).length;
};

export const refreshCollectionMap = (
  collectionMap: Map<string, CollectionRef>,
  productMap: Map<string, ProductRef>
) => {
  const collections = Array.from(collectionMap.values());
  collections.forEach((c) => {
    c.children = [];
    c.totalProductCount = 0;
  });
  collections.forEach((c) => {
    c.parent = c.group.parentId ? collectionMap.get(c.group.parentId) : null;
    if (c.parent) {
      c.parent.children = [...c.parent.children, c];
      //c.parent.totalProductCount += c.group.items.length;
    }
    c.productCount = c.group.items.length;
  });

  Array.from(productMap.values()).forEach((p) => {
    p.collections = [];
  });

  collections.forEach((c) => {
    c.parents = getParents(c);
    c.handle = createPermalink(
      c.parents.reduce((acc, p) => `${acc}${p.group.name}-`, "") + c.group.name
    );
    //c.name = c.group.name;
    //c.description = c.group.description;
    c.fullName =
      c.parents.reduce((acc, p) => `${acc}${p.group.name} | `, "") +
      c.group.name;
    c.totalProductCount = getProductCount(c);

    c.group.items.forEach((id) => {
      const p = productMap.get(id);
      if (p) {
        p.collections = [...p.collections, c];
      }
    });
  });
};

export const removeCollectionProducts = (
  collectionMap: Map<string, CollectionRef>,
  productIds: string[]
) => {
  const collections = Array.from(collectionMap.values());
  collections.forEach((c) => {
    c.group.items = c.group.items.filter(
      (i) => !productIds.some((id) => id === i)
    );
    c.productCount = c.group.items.length;
  });
  collections.forEach((c) => {
    c.totalProductCount = getProductCount(c);
  });
};

export const mapProductOptions = (
  p: Product,
  optionMap: Map<number, ProductOption>
) => {
  p.variants?.forEach((v) => {
    v.options.forEach((vo) => {
      const opt = optionMap.get(vo.optionId);
      if (opt) {
        vo.name = opt.name;
        if (vo.value) opt.count++;
      }
    });
  });
};

export const refreshProductOptions = (
  productMap: Map<string, ProductRef>,
  optionMap: Map<number, ProductOption>
) => {
  const options = Array.from(optionMap.values());
  options.forEach((o) => {
    o.count = 0;
  });

  Array.from(productMap.values()).forEach((p) => {
    mapProductOptions(p.product, optionMap);
  });
};

export const createCategory = (storageUrl: string): CategoryRef => {
  const category: Category = {
    id: EMPTY_GUID,
    name: "",
    rank: 0,
    fields: [...reservedFields],
    options: [],
  };
  return mapCategory(category, new Map(), storageUrl);
};

export const createCollection = (
  storageUrl: string,
  props?: Partial<Collection>
): CollectionRef => {
  const collection: Collection = {
    id: EMPTY_GUID,
    name: "",
    rank: 0,
    items: [],
    ...props,
  };
  return mapCollection(collection, storageUrl);
};

export const getCollectionProductIds = (collection: CollectionRef) => {
  if (!collection) return [];
  return collection.children.reduce(
    (acc, c) => [...acc, ...getCollectionProductIds(c)],
    collection.group.items
  );
};

export const getProductImageFields = (product: ProductRef) => {
  const fields = product.category.group.fields
    .filter((f) => f.dataType === DataTypeName.Image)
    .map((f) => f.name)
    .sortList();
  return fields;
};

export const getAllImageFields = (db: ProductDb) => {
  const imageFields = Array.from(db.categoryMap.values())
    .reduce(
      (acc, c) => [
        ...acc,
        ...c.group.fields
          .filter(
            (f) =>
              f.dataType === DataTypeName.Image && f.name !== DefaultImageField
          )
          .map((f) => f.name),
      ],
      [] as string[]
    )
    .filter(onlyUnique)
    .sortList();

  return [DefaultImageField, ...imageFields];
};

export const changeCategory = (
  db: ProductDb,
  p: Product,
  categoryId: string,
  ref?: ProductRef
) => {
  const category = db.categoryMap.get(categoryId);
  if (category) {
    const oldCategory = db.categoryMap.get(p.categoryId);
    oldCategory.totalProductCount--;
    const fields = p.fields.map((f) => ({
      ...f,
      fieldId: category.group.fields.find(
        (cf) => cf.name === oldCategory.fieldMap.get(f.fieldId)?.name
      )?.fieldId,
    }));
    return mapProduct(
      { ...p, fields, categoryId },
      db.categoryMap,
      db.optionMap,
      db.storageUrl,
      ref
    );
  }
};

export const compareProductFields = (
  field: string,
  culture: string,
  pa?: ProductRef,
  pb?: ProductRef
) => {
  const a = pa?.fieldMap.get(field);
  const b = pb?.fieldMap.get(field);

  if (!a?.value || !b?.value) return a?.value ? 1 : b?.value ? -1 : 0;

  if (
    a.dataType === DataTypeName.Number &&
    b.dataType === DataTypeName.Number
  ) {
    return parseFloat(a.value) - parseFloat(b.value);
  }

  if (a.dataType === DataTypeName.Money && b.dataType === DataTypeName.Money) {
    return (
      parseMoney(a.value, culture).value - parseMoney(b.value, culture).value
    );
  }

  if (field === ExtendedDataType.Category) {
    return pa.category?.group.name.localeCompare(pb.category?.group.name);
  }

  return a.value.localeCompare(b.value);
};
