import { Component } from "react";
import { Box, Typography } from "@mui/material";
import { logError } from "../services/logging";

interface Props {
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error) {
    logError(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <h1>Something went wrong...</h1>
            <div>Please try again or contact us in the chat.</div>
            {this.state.error?.message && (
              <Typography
                color="text.secondary"
                variant="caption"
                component="div"
                sx={{ mt: 3 }}
              >
                Error: {this.state.error.message}
              </Typography>
            )}
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
