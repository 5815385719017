import { dataPatterns } from "../data/data-types";
import { AccountUser, User } from "./account";

export type UserProfileData = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

export type UserSecurityData = {
  password?: string;
  oldPassword?: string;
};

export type ResetPasswordData = {
  password?: string;
  resetEmail?: string;
  token?: string;
};

const fields = {
  email: "Email",
  password: "Password",
  firstName: "First Name",
  lastName: "Last Name",
  businessName: "Business Name",
  oldPassword: "Old Password",
};

export type BusinessData = {
  namespace?: string;
  businessName?: string;
  webSite?: string;
  logo?: string;
  brandColor?: string;
  userSiteLanguage?: string;
  culture?: string;
  ordersEmail?: string;
  invoiceDetails?: string;
  catalogUrl: string;
};

export type AccountSettings = {
  canSurvey?: boolean;
  emailDigest?: boolean;
  enableIntlFonts?: boolean;
  emailDigestOriginal?: boolean;
};

export type AccessLog = {
  id: string;
  email: string;
  created: string;
  source: string;
  catalogId?: string;
};

export type AccessLogRequest = {
  pageNum?: number;
  pageSize?: number;
};

export type AccessLogResponse = {
  logs: AccessLog[];
  total: number;
};

export type UserPermission = { name: string; description: string };

export type AccountUsersResponse = {
  users: AccountUser[];
  admin?: User;
  permissions?: UserPermission[];
};

export type CancelSurveyResponse = {
  reason: string;
  feedback?: string;
  timestamp?: number;
};

export const supportedLanguages = [
  { value: "en", name: "English" },
  { value: "fr", name: "French" },
  { value: "de", name: "German" },
  { value: "it", name: "Italian" },
  { value: "no", name: "Norwegian" },
  { value: "pl", name: "Polish" },
  { value: "pt", name: "Portuguese" },
  { value: "ru", name: "Russian" },
  { value: "es", name: "Spanish" },
];

export const supportedNumberFormats = [
  { value: "en", name: "English (1,295.50)" },
  { value: "es", name: "European (1.295,50)" },
];

export const validateAccountField = (
  data,
  fieldName: string,
  required?: boolean,
  validExtUser?: boolean
) => {
  const value = data[fieldName];
  switch (fieldName) {
    case "email":
      if (!value || !dataPatterns.email.test(value)) {
        return "Please enter a valid email";
      }
      break;
    case "password":
      if ((!value || value.length < 8) && !validExtUser) {
        return "Password must be at least 8 characters";
      }
      break;
    case "token":
      if (!value) {
        return "Invalid token provided";
      }
      break;
    default:
      if (!value && required) {
        return fields[fieldName]
          ? `Please enter ${fields[fieldName]}`
          : "Invalid value for " + fieldName;
      }
      break;
  }
  return "";
};
