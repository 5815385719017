import { useCallback, useState } from "react";
import { EntityType } from "../../features/data/data-types";
import { UpgradeDialog } from "./UpgradeDialog";
import { useNavigate } from "react-router-dom";

export const useUpgradeDialog = () => {
  const [upgradeType, setUpgradeType] = useState<EntityType>();

  const navigate = useNavigate();

  const openUpgradeModal = (type: EntityType) => {
    setUpgradeType(type);
    if (type === "catalogs") {
      navigate("/catalogs");
    }
  };
  const renderUpgradeModal = useCallback(() => {
    if (!upgradeType) return null;

    return (
      <UpgradeDialog
        type={upgradeType}
        isOpen={!!setUpgradeType}
        onClose={() => setUpgradeType(null)}
      />
    );
  }, [upgradeType]);

  return { renderUpgradeModal, openUpgradeModal };
};
