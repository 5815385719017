export type Permission = "admin" | "catalogs" | "products";
export type PermissionType = { [key in Permission]: string };

export const FreePlan = "free";

export type AccountSummary = {
  //lastRefresh: string;
  //lastImageRefresh: string;
  itemsCount: number;
  categoriesCount: number;
  imagesCount: number;
  imagesSize: number;
  imageSizeMb: number;
  catalogsCount: number;
  templatesCount: number;
  usersCount: number;
  activeUsersCount: number;
  publishedCatalogs: number;
  sessions: number;
  showrooms: number;
  showroomPrivacy: boolean;
  newOrders: number;
  prevSessionDays: number;
  profile: {
    accountId: number;
    name: string;
    value: string;
    number: number;
    updated: string;
  }[];
  // imageSizeFormatted: string;
  // spaceLimit: string;
  // itemsLimit: string;
  // catalogsLimit: string;
  // usersLimit: string;
  features: {
    showroom: boolean;
    orders: boolean;
    collections: boolean;
    templates: boolean;
    layouts: boolean;
  };
  metaProfile: {
    item1: string;
    item2: string;
  }[];
};

export type AccountRef = {
  id: number;
  namespace: string;
  imageNamespace: string;
  businessName: string;
  created: string;
  isAdmin: boolean;
  permissions: PermissionType;
  category: string;
  planCode: string;
  planName: string;
  priceDescription: string;
  brandColor?: string;
  logo: string;
  summary: AccountSummary;
  limits: AccountLimits;
  config: AccountConfig;
  apiKey: string;
  age?: string;
  isActivated: boolean;
  isPaying: boolean;
  gateway?: string;
  monthlyPrice?: number;
  subscriptionStatus?: string;
  subscriptionChanged?: string;
  errorStatus?: string;
  support?: "premier" | "standard" | "basic";
  shop?: string;
  signUpSource?: string;
  urlReferrer?: string;
  enableIntlFonts?: boolean;
};

export type User = {
  id: number;
  email: string;
  name: string;
  accounts: AccountRef[];
  hmac: string;
  created: string;
  role: string;
  firstName?: string;
  lastName?: string;
};

export type AccountLimits = {
  variants: number;
  pageLimit: number;
  items?: number;
  catalogs?: number;
  users?: number;
  space?: number;
};

export type AccountConfig = {
  imageStorageUrl: string;
  culture: string;
  catalogPath: string;
  apiV1Url: string;
  pdfApiUrl: string;
};

export type AppSession = {
  token: string;
  succeeded?: boolean;
  user?: User;
  account?: AccountRef;
  message?: string;
  provider?: string;
  requiresSignup?: boolean;
};

export type AccountInfo = {
  summary: AccountSummary;
};

export type DecodedToken = {
  provider: string;
  su: string;
  exp: number;
};

export type ExternalUser = {
  email: string;
  firstName: string;
  lastName: string;
  provider: string;
  emailExists: boolean;
};

/*
public class AccountUser
{
    public static readonly List<string> PermissionKeys = new() { "Admin", "Catalogs", "Products" };

    public static readonly List<UserPermission> AllPermissions = new()
    {
            new() { Name = "Catalogs", Description = "Catalogs & Templates"},
            new() { Name = "Products", Description = "Products, Categories & Collections"}
        };

    public const string PendingStatus = "Pending";

    public const string ActiveStatus = "Active";

    private readonly JsonValue<UserRole> _role;

    public const int MaxPossibleUsers = 5;

    public int Id { get; set; }

    public int AccountId { get; set; }

    [JsonIgnore]
    public virtual Account? Account { get; set; }

    public int? UserId { get; set; }

    public virtual User? User { get; set; }

    public string? Email { get; set; }

    public DateTime Modified { get; set; }

    public DateTime Created { get; set; }

    public DateTime? Activated { get; set; }

    public string? RoleJson { get; set; }

    public string? Status { get; set; }

    public AccountUser()
    {
        _role = new JsonValue<UserRole>(this, GetType().GetProperty(nameof(RoleJson)));
    }

    [NotMapped]
    public UserRole? Role
    {
        get => _role.Value;
        set => _role.Value = value;
    }

    //[JsonIgnore]
    //public string? Token => Cryptographer.EncryptString(Id.ToString());

    public string? Name => User?.CapitalizedName ?? Email;

    public List<string> Permissions => Role?.Permissions?.Where(p => p.Value).Select(p => p.Key).ToList() ?? new List<string>();

    //public string InviteLink => UserId == null ? WebContext.ApplicationSecureUrl + "account/join?email=" + Email + "&code=" + Token : null;

    [JsonIgnore]
    public string RoleDescription => Role?.Description ?? "No role";
}

public class UserRole
{
    public const string FullAccess = "Full";

    public string? Access { get; set; }

    public Dictionary<string, bool> Permissions { get; set; } = new();

    [JsonIgnore]
    public string Description => Access == FullAccess
        ? "Full"
        : "Restricted" + Permissions?.Where(p => p.Value).Aggregate(":", (s, pair) => s + " [" + pair.Key + "]");
}

public class UserPermission
{
    public string Name { get; set; } = "";

    public string Description { get; set; } = "";
}
*/

export type AccountUser = {
  id?: number;
  userId?: number;
  user?: User;
  email?: string;
  modified?: string;
  created?: string;
  activated?: string;
  status?: "Active" | "Pending";
  role?: UserRole;
  name?: string;
  permissions?: string[];
  inviteLink?: string;
};

export type UserRoleAccess = "Full" | "Restricted";

export type UserRole = {
  access: UserRoleAccess;
  permissions: { [key: string]: boolean };
};
